@import "assets/scss/variables";
@import "~bootstrap/scss/bootstrap.scss";
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+Thai:wght@400;700&family=Roboto:wght@400;700&display=swap");
@import "assets/scss/fonts-slip.scss";

body {
  font-family: "Roboto", "Noto Sans Thai", sans-serif !important;
  font-size: 16px !important;
  margin: 0;
  padding: 0;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.app {
  display: flex;
  flex-wrap: nowrap;
  width: calc(100vw + 260px);
  height: 100vh;
  overflow-x: hidden;
  background-color: $pink-light;
  -webkit-transform: translateX(-260px);
  transform: translateX(-260px);
  will-change: transform;
  transition: transform 0.25s ease;

  &.toggle {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  width: 100vw;
  -webkit-transform: translateX(0);
  transform: translateX(0);
}

.navbar {
  padding-top: 1rem;
  padding-bottom: 1rem;

  @media (min-width: 1024px) {
    padding-top: 0.5rem;
    padding-bottom: 0.6rem;
  }
}

.form-floating > .form-control ~ label {
  opacity: 0.65 !important;
}

.btn {
  white-space: nowrap;
  border-radius: 8px !important;
}

.btn-primary {
  color: #fff !important;
}

.btn-outline-primary:hover {
  color: #fff !important;
}

.content {
  min-width: 100vw;
  overflow: inherit;

  > .container {
    min-height: calc(100vh - 152px);
  }

  @media (min-width: 768px) {
    min-width: 0;
    width: 100%;
  }

  @media (min-width: 992px) {
    > .container {
      min-height: calc(100vh - 72px);
    }
  }
}

.sos-nav-logo {
  width: 120px;
}

.sos-nav-toggle {
  display: flex;
  align-items: center;
  width: 24px;

  i {
    display: none;
    font-size: 24px;

    &.open {
      display: block;
    }
  }
}

// --------- start : ant-pinker -----------

.ant-picker {
  padding: 14px 11px 14px 16px !important;
  border-radius: 8px !important;
}
.ant-picker-input > input {
  font-size: 16px !important;
}
.ant-picker-range .ant-picker-active-bar {
  background: $primary-color !important;
}

.ant-picker:hover,
.ant-picker-focused {
  border-color: $primary-color !important;
}

.ant-picker-focused,
.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused {
  border-color: $primary-color !important;
  box-shadow: 0 0 0 2px rgba($primary-color, 0.2) !important;
}

.ant-picker-separator {
  height: auto !important;
}

.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  background: $primary-color !important;
}

.ant-picker-cell-in-view.ant-picker-cell-range-start:not(
    .ant-picker-cell-range-start-single
  )::before,
.ant-picker-cell-in-view.ant-picker-cell-range-end:not(
    .ant-picker-cell-range-end-single
  )::before {
  background: $primary-color !important;
}

.ant-picker-cell-in-view.ant-picker-cell-in-range::before {
  background: rgba($primary-color, 0.1) !important;
}

.ant-picker-cell-in-view.ant-picker-cell-range-start:not(
    .ant-picker-cell-range-start-single
  ):not(.ant-picker-cell-range-end)
  .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-end:not(
    .ant-picker-cell-range-end-single
  ):not(.ant-picker-cell-range-start)
  .ant-picker-cell-inner {
  width: 100%;
}

.ant-picker-date-panel
  .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end
  .ant-picker-cell-inner::after {
  right: -6px !important;
  background: rgba($primary-color, 0.2) !important;
}

.ant-picker-date-panel
  .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start
  .ant-picker-cell-inner::after {
  left: -6px !important;
  background: rgba($primary-color, 0.2) !important;
}

.ant-picker-cell:hover:not(.ant-picker-cell-in-view) .ant-picker-cell-inner,
.ant-picker-cell:hover:not(.ant-picker-cell-selected):not(
    .ant-picker-cell-range-start
  ):not(.ant-picker-cell-range-end):not(.ant-picker-cell-range-hover-start):not(
    .ant-picker-cell-range-hover-end
  )
  .ant-picker-cell-inner {
  background: rgba($primary-color, 0.2) !important;
}

.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  border-color: $primary-color !important;
}

// --------- end : ant-pinker -----------

// ------- start : react-select ---------
.search-select {
  &__control {
    min-height: 0 !important;
    position: absolute;
    top: 0;
    left: 0;
    border-width: 0 !important;
    background: transparent !important;
    padding: 1.625rem 2.25rem 0.625rem 0.75rem !important;
  }

  &__value-container,
  &__input-container {
    padding: 0 !important;
    margin: 0 !important;
  }

  &__indicator {
    display: none !important;
  }
}

// -------- end : react-select ----------

.btn-link-view {
  cursor: pointer;
}
.btn-link-view:hover {
  color: #1890ff;
}

.date-picker-employee label {
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.date-picker-employee.ant-picker {
  width: 100%;
  padding: 0 !important;
}

.date-picker-employee .ant-picker-input input {
  padding: 0.625rem 0 0.625rem 0.75rem;
}

.date-picker-employee .ant-picker-suffix {
  font-size: 20px;
  padding-right: 10px;
}

.date-picker-employee .ant-picker-clear {
  font-size: 20px;
  padding-right: 10px;
}

// .ant-drawer-title { font-size: 20px;}
// .ant-form-item-label > label, div.ant-drawer-title {font-size: 18px;}

.ant-input-group .ant-input-affix-wrapper:not(:first-child),
.ant-input-search .ant-input-group .ant-input-affix-wrapper:not(:first-child) {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.ant-input-group > .ant-input:first-child,
.ant-input-group-addon:first-child {
  border-top-left-radius: 8px !important;
  border-bottom-left-radius: 8px !important;
}
.ant-form .ant-input {
  border-radius: 8px !important;
  // line-height: 1.9715 !important;

  &:hover {
    border-color: $primary-color !important;
  }
}

.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: $primary-color !important;
}

.ant-form .ant-select {
  border-radius: 0.25rem !important;
  line-height: 1.9715 !important;
}
.ant-form .ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 8px !important;
}
.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 8px !important;
}

.ant-form
  .ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector {
  height: 50px !important;
  padding: 3px 11px !important;
}

.modal-password .ant-modal-title {
  font-size: 20px;
}
.modal-password .ant-modal-body .ant-form-item-label > label {
  font-size: 16px;
}
.filter-wrapper .ant-select {
  border-radius: 0.25rem !important;
  line-height: 1.9715 !important;
}
.ant-form .text-end {
  text-align: center !important;
}
.ant-form .text-end button {
  min-width: 30%;
  margin: 0 5px;
}

.filter-wrapper .ant-select-selection-selected-value {
  width: 100%;
}

@media (min-width: 992px) {
  .ant-drawer:not(.drawerSalary) .ant-drawer-content-wrapper {
    width: 500px !important;
  }
}

// Menu User Profile
.ant-dropdown-menu-item,
.ant-dropdown-menu-submenu-title {
  font-size: 16px !important;
  padding: 8px 12px !important;
}

.idcard-uploader .ant-upload {
  overflow: hidden;
}

.ant-space-item .btn {
  white-space: nowrap;
}

.name-system {
  display: none;
}

.ant-dropdown-menu-item a {
  text-decoration: none !important;
}

.ant-table-empty .ant-table-thead > tr > th {
  display: none;
}

@media (min-width: 992px) {
  .name-system {
    display: block;
  }
}

.card-head {
  .btn {
    width: 100%;
  }

  .ant-picker {
    min-width: 200px;
  }

  @media (min-width: 768px) {
    .btn {
      width: auto;
    }

    .ant-picker {
      min-width: 150px;
    }
  }
}

.filter-wrapper {
  border-top: 1px solid rgba($grey-color-3, 0.25);
  border-bottom: 1px solid rgba($grey-color-3, 0.25);
  padding-top: 16px;
  flex-direction: column;

  .filter-group {
    display: flex;
    align-items: center;
    margin-bottom: 16px;

    label {
      white-space: nowrap;
      min-width: 64px;
    }

    .ant-select {
      width: 100%;
      padding-left: 8px;
    }
  }

  @media (min-width: 768px) {
    display: flex;
    justify-content: flex-end;

    .filter-group {
      label {
        min-width: auto;
      }

      &:not(:last-child) {
        margin-right: 24px;
      }
    }
  }
  @media (min-width: 1400px) {
    flex-direction: row;
  }
}

.anticon.ant-notification-notice-icon-success {
  color: #fff !important;
}
.ant-notification-notice-success {
  background-color: #00c9a4 !important;
  color: #fff !important;
}

.anticon.ant-notification-notice-icon-error {
  color: #fff !important;
}
.ant-notification-notice-error {
  background-color: #dc3545 !important;
  color: #fff !important;
}

.anticon.ant-notification-notice-icon-warning {
  color: #fff !important;
}
.ant-notification-notice-warning {
  background-color: #fd7e14 !important;
  color: #fff !important;
}

.ant-notification-notice-message {
  color: #fff !important;
}
.ant-notification-notice-close-icon {
  color: #fff !important;
}

@media only screen and (max-width: 576px) {
  .ant-pagination-options {
    display: block !important;
  }
}

.ant-switch-checked {
  background-color: $success !important;
}

.logo-website {
  font-size: 28px;
  font-weight: normal;
  color: #0156d6;
}

.ant-dropdown-menu {
  border-radius: 16px !important;
  padding: 8px 0 !important;
  box-shadow: 2px 4px 16px #ff78755e;
  overflow: hidden;
}

.ant-dropdown-menu-item {
  &:hover {
    background: $pink-light !important;
  }
}

.ant-dropdown-menu-title-content {
  padding: 0 8px;
}

.ant-input-affix-wrapper,
textarea.ant-input {
  border-radius: 8px !important;
}

.ant-btn {
  border-radius: 8px !important;

  &:hover {
    border-color: #c26aff !important;
  }
}

.ant-btn-default {
  &:hover {
    color: #c26aff !important;
  }
}

.ant-btn-primary {
  color: #fff !important;
  background: linear-gradient(336deg, #fa70eb, #ac92f4) !important;
  border: 0 !important;
  border-radius: 8px !important;

  &:hover {
    transform: scale(1.025);
    box-shadow: 2px 4px 16px #ff78755e;
  }
}

.ant-btn-dangerous {
  color: #fff !important;
  background: linear-gradient(336deg, #ff2c37, #f86f76) !important;
  border: 0 !important;
  border-radius: 8px !important;

  &:hover {
    transform: scale(1.025);
    box-shadow: 2px 4px 16px #ff78755e;
  }
}

.swal2-popup {
  border: 0 !important;
  border-radius: 20px !important;
}

.swal2-confirm {
  color: #fff !important;
  background: linear-gradient(336deg, #fa70eb, #ac92f4) !important;
  border: 0 !important;
  border-radius: 8px !important;
}

.swal2-cancel {
  color: #fff !important;
  background: linear-gradient(336deg, #ff2c37, #f86f76) !important;
  border: 0 !important;
  border-radius: 8px !important;
}

.btn-primary {
  color: #fff !important;
  background: linear-gradient(336deg, #fa70eb, #ac92f4) !important;
  transition: all 0.25s ease;

  &:hover {
    transform: scale(1.025);
    box-shadow: 2px 4px 16px #ff78755e;
  }
}

.btn-dangerous {
  color: #fff !important;
  background: linear-gradient(336deg, #ff2c37, #f86f76) !important;
  transition: all 0.25s ease;

  &:hover {
    transform: scale(1.025);
    box-shadow: 2px 4px 16px #ff78755e;
  }
}

.form-floating > .form-control {
  border-radius: 8px !important;
}

.ant-table-sticky-scroll {
  opacity: 0 !important;
}

.button-menu {
  width: 48%;
  height: 80px !important;
  margin: 1%;

  @media only screen and (min-width: 992px) {
    width: 31.3%;
    height: 100px !important;
    margin: 1%;
  }
}

.list-menu {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
}

.card-credit-balance {
  display: flex;
  background: linear-gradient(336deg, #fa70eb, #ac92f4);

  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.02);
  border-radius: 16px;
  padding: 24px;

  @media only screen and (min-width: 768px) {
    padding: 32px;
  }
}

.card-credit-withdraw {
  display: flex;
  background: linear-gradient(336deg, #fa70eb, #ac92f4);

  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.02);
  border-radius: 16px;
  padding: 24px;

  @media only screen and (min-width: 768px) {
    padding: 32px;
  }
}

.icon-wallet {
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(312deg, #ffc5c5, #fff);
  box-shadow: 2px 4px 16px #ff78755e;
  color: rgb(255, 255, 255);
  border-radius: 16px;
  font-size: 20px;
  margin-right: 24px;

  img {
    width: 32px;
  }

  @media only screen and (min-width: 768px) {
    margin-right: 32px;
  }
}

.text-wallet {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);

  h2 {
    color: rgb(255, 255, 255);
    font-size: 22px;
    font-weight: bold;
    margin-bottom: 4px;
  }

  p {
    font-size: 16px;
    color: rgba(255, 255, 255, 0.9);
    margin: 0;
  }
  span {
    font-size: 12px;
    color: rgba(255, 255, 255, 0.9);
    margin: 0;
  }
}

.card-body {
  background: #fff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.02);
  border-radius: 16px;
  overflow: hidden;

  height: auto;
  padding: 22px 50px 0px 25px;
  margin-bottom: 10px;

  h2 {
    margin-bottom: 0;
  }

  @media only screen and (min-width: 374px) {
    height: auto;
    padding: 18px 85px 0px 25px;
    margin-bottom: 10px;
  }

  @media only screen and (min-width: 768px) {
    height: auto;
    padding: 18px 16px 0px;
    margin-bottom: 10px;
  }
}

.card-search {
  background: #fff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.02);
  border-radius: 16px;
  overflow: hidden;

  height: auto;
  padding: 23px 258px 0px 186px;
  margin-bottom: 10px;

  h2 {
    margin-bottom: 0;
  }

  @media only screen and (min-width: 374px) {
    padding: 18px 85px 0px 31px;
  }

  @media only screen and (min-width: 768px) {
    padding: 23px 258px 0px 186px;
  }

  @media only screen and (min-width: 992px) {
    padding: 23px 283px 0px 292px;
  }
}

.card-deposit-withdraw {
  background: #fff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.02);
  border-radius: 16px;
  overflow: hidden;

  height: auto;
  padding: 18px 35px 20px 30px;
  margin-bottom: 10px;

  h2 {
    margin-bottom: 0;
  }

  @media only screen and (min-width: 374px) {
    padding: 18px 35px 20px 30px;
  }

  @media only screen and (min-width: 768px) {
    padding: 18px 35px 20px 30px;
  }

  @media only screen and (min-width: 992px) {
    padding: 18px 35px 20px 30px;
  }
}

.card-des-info {
  background: #fff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.02);
  border-radius: 16px;
  overflow: hidden;

  height: auto;
  padding: 23px 18px 0px 18px;
  margin-bottom: 10px;

  h2 {
    margin-bottom: 0;
  }
}

.ant-radio-button-wrapper {
  width: 21%;
  margin: 1%;
  border-start-start-radius: 0px !important;
  border-end-start-radius: 0px !important;
  border-start-end-radius: 0px !important;
  border-end-end-radius: 0px !important;
  padding-inline: 22px !important;

  &:hover {
    color: #c26aff;
  }

  @media only screen and (min-width: 992px) {
    width: 8%;
    margin: 1%;
  }
}

.card-number {
  background: #fff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.02);
  border-radius: 16px;
  overflow: hidden;

  height: auto;
  padding: 22px 0px 0px 25px;
  margin-bottom: 10px;

  height: 40pc;
  overflow: scroll;

  @media only screen and (min-width: 992px) {
    // height: auto;
    // height: 110px;
    padding: 18px 18px 0px 25px;
    margin-bottom: 10px;
  }
}

.card-checked-number {
  background: #fff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.02);
  border-radius: 16px;
  overflow: hidden;

  height: auto;
  padding: 22px 0px 0px 25px;
  margin-bottom: 10px;

  height: auto;
  // height: 110px;
  // overflow: scroll;

  @media only screen and (min-width: 992px) {
    // height: auto;
    // height: 110px;
    padding: 18px 18px 0px 25px;
    margin-bottom: 10px;
  }
}

.card-content {
  background: #fff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.02);
  border-radius: 16px;
  overflow: hidden;

  height: auto;
  padding: 22px 0px 0px 24px;
  margin-bottom: 10px;

  @media only screen and (min-width: 992px) {
    padding: 18px 18px 0px 25px;
    margin-bottom: 10px;
  }
}

.ant-radio-button-wrapper-checked {
  background-color: #c26aff;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  color: #c26aff;

  &:hover {
    color: #c26aff;
  }
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  z-index: 1;
  color: #c26aff;
  background: #ffffff;
  border-color: #c26aff;

  &:before {
    background-color: #c26aff;
  }
}

.ant-radio-button-wrapper-checked:not(
    .ant-radio-button-wrapper-disabled
  ):hover::before {
  background-color: #c26aff;
}

.ant-radio-button-wrapper-checked:not(
    .ant-radio-button-wrapper-disabled
  ):hover {
  border-color: #c26aff;
}

.ant-radio-button-wrapper-checked:not(
    .ant-radio-button-wrapper-disabled
  ):first-child {
  border-color: #c26aff;
}

// checkbox

.ant-checkbox-group {
  display: flex;

  > label.ant-checkbox-wrapper {
    font-size: 14px;
    align-items: center;
    margin: 1%;
    width: 21%;
    border: 1px solid rgb(217, 217, 217);
    border-right-width: 1px;

    > span:first-child {
      display: none;
    }
    > span:last-child {
      padding: 0;
      margin-left: 35%;

      @media only screen and (min-width: 429px) {
        margin-left: 32%;
      }

      @media only screen and (min-width: 576px) {
        margin-left: 38%;
      }

      @media only screen and (min-width: 992px) {
        margin-left: 32%;
      }
    }

    @media only screen and (min-width: 992px) {
      width: 8%;
    }
  }

  > label.ant-checkbox-wrapper-checked {
    border: 1px solid #c26aff;
    color: #c26aff;
  }
  > label.ant-checkbox-wrapper-checked + label.ant-checkbox-wrapper {
    border-left-width: 1px;
  }

  > label.ant-checkbox-wrapper:last-child {
    border-right-width: 1px;
  }
}

.number-credit-balance {
  text-align: right;
  margin-right: 15%;

  @media only screen and (min-width: 992px) {
    margin-right: 0;
  }
}

.header-title {
  text-align: center;
  margin-bottom: 6%;
  @media only screen and (min-width: 992px) {
    margin-bottom: 2%;
  }
}

.sub-title {
  text-align: center;
  @media only screen and (min-width: 992px) {
    margin-bottom: 2%;
  }
}

.search-number {
  text-align: right;
  padding-left: 1%;
  padding-bottom: 3%;
}

.ant-form-inline .ant-form-item-with-help {
  margin-bottom: 0px;
}

.ant-form-inline {
  padding-top: 4%;
  padding-bottom: 8%;

  @media only screen and (min-width: 992px) {
    padding-bottom: 3%;
    padding-top: 2%;
  }
}

.ant-form-item .ant-form-item-control-input-content {
  text-align: left;
}

.card-order {
  background: #fff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.02);
  border-radius: 16px;
  overflow: hidden;

  height: auto;
  padding: 22px 0px 0px 25px;
  margin-bottom: 10px;

  height: 40pc;
  overflow: scroll;

  @media only screen and (min-width: 992px) {
    // height: auto;
    // height: 110px;
    padding: 18px 18px 0px 25px;
    margin-bottom: 10px;
  }
}

.input-search-number {

}

