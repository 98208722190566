// Colors
// $primary-color: #df4b4a;
$primary-color: #c26aff;
$secondary-color: #5952c0;
$white-color: #fff;
$orange-color: #fd7e14;
$green-color: #52D4BA;
$red-color: #FF4267;
$grey-color-1: #f5f6fa;
$grey-color-2: #6A6D78;
$grey-color-3: #8E8E8E;
$pink-light: #f4effe;
$black-color: #0a0a0a;

// bootstrap
$primary: $primary-color;
$warning: #fd7e14;
$info: $secondary-color;
$success: #00c9a4;
$btn-font-size-lg: 16px;
$btn-padding-x: 16px;
$btn-padding-y: 12px;
